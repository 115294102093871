import React from 'react';
import styles from '../../../styles/index-page/infos.module.css';
import Expander from '../../atoms/index-page/text-expander';

const Info3 = () => {

    const [displayDetails, updateDetails] = React.useState(false);

    const toggleDetails = () => updateDetails(d => !d);

    return (
        <div className={styles.containerFluid}>
            <div className={styles.loadingContainer}>
                <div className={'row'}>
                    <div className="col-md-6 order-md-last mt-5 d-flex justify-content-center align-items-center">
                        <div style={{ zIndex: 1 }}>
                            <div className={styles.promotionStep}>03</div>
                            <h2 className={styles.promotionTitle}>
                                Alltid fast pris på tjänstepension
                            </h2>
                            <p className={styles.promotionDetails}>Pensure ser till att dina pengar växer utan att ätas upp av onödiga avgifter.</p>

                            {displayDetails && <div className={styles.promotionDetails}>
                                <p className={styles.promotionDetails}>Vi erbjuder nämligen tjänstepension till ett fast pris, i form av en licensavgift som är avdragsgill via ditt bolag.</p>
                                <p className={styles.promotionDetails}>Dessutom har vi förhandlat fram rabatt på de vanligaste aktiva fonderna, vilket betyder att du som kund, totalt sett, får marknadens lägsta avgifter på tjänstepensionen.</p>
                            </div>}
                            <Expander expanded={displayDetails} expansionChanged={toggleDetails} />
                        </div>
                    </div>
                    <div className="col-md-6 position-relative order-md-first">
                        <img src="img/iphone-tilted-2x.png" height={600} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info3