import React from 'react';
import styles from '../../../styles/index-page/infos.module.css';
import Expander from '../../atoms/index-page/text-expander';

const Info1 = () => {

    const [displayDetails, updateDetails] = React.useState<boolean>(false);

    const toggleDetails = () => {
        updateDetails(s => !s);
    }

    return (
        <div className={styles.containerFluid}>
            
            <div className={styles.loadingContainer}>
                <div className="row">
                    <div className="col-md-6">
                        <img src="img/iphone-front.png" style={{maxWidth: '100%'}} />
                    </div>
                    <div className="col-md-6 mt-5 d-flex justify-content-center align-items-center">
                        <div>
                            <div className={styles.promotionStep}>01</div>
                            <h2 className={styles.promotionTitle}>
                                Vad är Pensure?
                            </h2>
                            <p className={styles.promotionDetails}>Pensure är den första helt digitaliserade plattformen för tjänstepension.</p>

                            {displayDetails && <div className={styles.promotionDetails}>
                                <p className={styles.promotionDetails}>Med avgiftsfritt sparande och garanterad rabatt på hållbara fonder gör vi det enkelt för företagare att ta ansvar för framtiden.</p>
                                <p className={styles.promotionDetails}>Som uppstickare i pensionsbranschen har vi förhandlat fram villkor för våra kunder som tidigare bara varit tillgängliga för stora bolag och koncerner.</p>
                                <p className={styles.promotionDetails}>Vi utmanar banker och försäkringsbolag med enkelhet, flexibilitet och framför allt - fast pris oavsett hur mycket dina pengar växer.</p>
                            </div>}
                            <Expander expanded={displayDetails} expansionChanged={toggleDetails} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info1;