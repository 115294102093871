import styles from '../../../styles/index-page/index-page.module.css';

const IndexHeader = () => {
    return (
        <div className="text-center">
            <h1 className={styles.headerTextTitle}>Pensure i samarbete med Bokio</h1>
            <p className={styles.secondaryTextTitle}>Pensure är den första helt digitaliserade plattformen för tjänstepension.</p>
            <p className={styles.secondaryTextTitle}>Vårt koncept är enkelt - små och medelstora företag ska få lika bra avtal för tjänstepensionen som stora bolag och koncerner alltid haft.</p>
            <p className={styles.secondaryTextTitle}>Pensure ser till att dina pengar växer utan att ätas upp av onödiga avgifter. Vi erbjuder nämligen tjänstepension till ett fast pris, i form av en licensavgift som är avdragsgill via ditt bolag.</p>
        </div>
    );
}

export default IndexHeader;