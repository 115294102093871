import React from 'react'
import styles from '../../../styles/employer/header.module.css';

const HeaderImage = () => {
    return (
        <div className={styles.headerImageContainer}>
            <img className={styles.headerImage} src="/svg/header-person.svg" alt="" />
        </div>
    )
}

export default HeaderImage;
