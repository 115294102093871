import styles from '../../../styles/index-page/infos.module.css';
import React from 'react';
import Expander from '../../atoms/index-page/text-expander';

const Info2 = () => {

    const [displayDetails, updateDetails] = React.useState<boolean>(false);

    const toggleDetails = () => updateDetails(s => !s);

    return (
        <div className={styles.secondStepContainer}>
            <div className={styles.containerFluid}>
                <div className={styles.loadingContainer}>
                    <div className="row">
                        <div className="col-md-6 mt-5 d-flex justify-content-center align-items-center">
                            <div>
                                <div className={styles.promotionStep}>02</div>
                                <h2 className={styles.promotionTitle}>
                                    Vårt koncept
                                </h2>
                                <p className={styles.promotionDetails}>Vårt koncept är enkelt - små och medelstora företag ska få lika bra avtal för tjänstepensionen som stora bolag och koncerner alltid haft.</p>

                                {displayDetails && <div className={styles.promotionDetails}>
                                    <p className={styles.promotionDetails}>För att lyckas med det har vi digitaliserat alla processer och förhandlat stenhårt med våra samarbetspartners om garanterad rabatt på fonder.</p>
                                    <p className={styles.promotionDetails}>Vi är hyggligt malliga över resultatet och jobbar nu för att fler företagshjältar ska få reda på hur enkelt det är att maximera sin pension med oss.</p>
                                </div>}
                                <Expander expanded={displayDetails} expansionChanged={toggleDetails} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src="img/ipad-tilted-2x.png" style={{ height: 500 }} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Info2;