import Link from 'next/link';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import styles from '../../../styles/index-page/dash-button.module.css';

interface IProps {
    header: string;
    link: string;
    click: () => void,
    loading: boolean
}

const DashButton: React.FC<IProps> = ({ header, link, click, loading }) => {
    return (
        <Link href={link}>
            <div className={styles.dashBtnContainer} onClick={click}>
                <div className={styles.dashBtnContainerContent}>
                    {!loading && header}
                    {loading && <ClipLoader color={'#FFF'} loading={loading} size={30} />}
                </div>
            </div>
        </Link>

    )
}

export default DashButton
