import React from 'react';

interface IProps {
    expanded: boolean;
    expansionChanged: (expanded: boolean) => void
}

const Expander: React.FC<IProps> = ({ expanded, expansionChanged }) => {

    const [expandedValue, toggleDetails] = React.useState(expanded);

    const toggle = () => {
        toggleDetails(expandedValue => !expandedValue);
        expansionChanged(expandedValue);
    }

    return (
        <button type="button" className="readmore-toggle d-flex justify-content-center align-items-center p-0" onClick={toggle}>
            {expandedValue && <>
                <div className="readmore-toggle--text">Stäng</div>
                <div className="isvg loaded readmore-toggle--icon position-relative active">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                        <path d="M5 5.982l3.646-3.646a.793.793 0 1 1 1.122 1.122L5.56 7.664a.793.793 0 0 1-1.122 0L.232 3.458a.793.793 0 1 1 1.122-1.122z"></path></svg>
                </div>
            </>}
            {!expandedValue &&<><div className="readmore-toggle--text">Fortsätt läsa</div>
            <div className="isvg loaded readmore-toggle--icon position-relative ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                    <path d="M5 5.982l3.646-3.646a.793.793 0 1 1 1.122 1.122L5.56 7.664a.793.793 0 0 1-1.122 0L.232 3.458a.793.793 0 1 1 1.122-1.122z"></path>
                    </svg>
            </div></>}
        </button>
    );
}

export default Expander;