import React, { useEffect, useState } from 'react';
import IndexHeader from './index-header';
import style from '../../../styles/index-page/index-page.module.css';
import HeaderImage from '../../atoms/Employer/HeaderImage';
import WhiteInHeader from '../../atoms/index-page/whiteInHeader';
import DashButton from '../../atoms/index-page/dash-button';
import { COMPARE_OFFER_STATUS, OFFER_STATUS } from '../../../config';
import axios from 'axios';
import Info1 from '../../molecules/index-page/info1';
import Info2 from '../../molecules/index-page/info2';
import Info3 from '../../molecules/index-page/info3';

const IndexPage = () => {

    const base_url = `/bokio/foretagare-teckna`
    const [navigationLink, setNavigationLink] = useState(`${base_url}/steg-1`);
    const [loading, setLoading] = useState(0);
    const [navigationCompareLink, setNavigationCompareLink] = useState(`/bokio/foretagare-jamfor`);
    const [navigationEmployerLink, setNavigationEmployerLink] = useState(`/bokio/arbetsgivare`);

    const getEntrepreneurLink = async () => {
        var offerId = localStorage.getItem('SUBSCRIPTION_OFFER_ID');
        if (offerId) {
            var request = await axios.get(`${OFFER_STATUS}/${offerId}/Status`);
            var status = request.data;
            switch (status) {
                case 'PlanChosen':
                    setNavigationLink(`${base_url}/steg-2?offerUid=${offerId}`)
                    break;

                case 'PendingSigning':
                    setNavigationLink(`${base_url}/steg-3?offerUid=${offerId}`)
                    break;

                default:
                    setNavigationLink(`${base_url}/steg-1?offerUid=${offerId}`)
                    break;
            }

        }
    }


    const getCompareLink = async () => {
        var offerId = localStorage.getItem('SUBSCRIPTION_COMPARE_OFFER_ID');
        if (offerId) {
            var request = await axios.get(`${COMPARE_OFFER_STATUS}/${offerId}/Status`);
            var status = request.data;
            switch (status) {
                case 'PendingPoASigning':
                    setNavigationLink(`/bokio/foretagare-jamfor/tack/${offerId}`)
                    break;

                default:
                    setNavigationCompareLink(`/bokio/foretagare-jamfor?offerId=${offerId}`)
                    break;
            }

        }
    }

    const getEmployerLink = async () => {
        var offerId = localStorage.getItem('EMPLOYER_OFFER_ID');
        if (offerId) {
            setNavigationEmployerLink(`/bokio/arbetsgivare?offerUid=${offerId}`);
        }
    }

    useEffect(() => {
        getEntrepreneurLink();
        getCompareLink();
        getEmployerLink();
    }, []);

    return (
        <>
            <div className={style.headerContainer}>
                <div className="row pb-md-4">
                    <div className="col-md-6">
                        <IndexHeader />
                    </div>
                    <div className="col-md-6">
                        <WhiteInHeader />
                    </div>
                </div>
                <HeaderImage />
            </div>
            <div className="container">
                <div className={'row m-5'}>
                    <div className="col-lg-4 mt-3">
                        <DashButton header="Företagare – teckna tjänstepension åt dig själv online"
                            link={navigationLink} click={() => setLoading(1)} loading={loading === 1}
                        />
                    </div>
                    <div className="col-lg-4 mt-3">

                        <DashButton header="Utmana din tjänstepension och jämför med Pensure"
                            link={navigationCompareLink} click={() => setLoading(2)} loading={loading === 2}
                        />
                    </div>
                    <div className="col-lg-4 mt-3">
                        <DashButton header="Arbetsgivare – räkna på vad ett tjänstepensionspaket skulle kosta ditt företag"
                            link={navigationEmployerLink} click={() => setLoading(3)} loading={loading === 3}
                        />
                    </div>
                </div>
                <Info1 />
                <Info2 />
                <Info3 />
            </div>
        </>
    )
}

export default IndexPage