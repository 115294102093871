import styles from '../../../styles/index-page/index-page.module.css';

const WhiteInHeader = () => {
   
    return (
        <div className={styles.pdfLinkContainer}>
            <div className="row">
                <div className="col-md-12 text-center">
                    <span className={styles.pdfContainerFirstText}>Som Bokio-användare får du 25% rabatt på första årets licensavgift, oavsett vilket försäkringspaket du väljer.</span>
                </div>
            </div>
        </div>
    )
}

export default WhiteInHeader